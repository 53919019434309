var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-col",
    { attrs: { span: 24 } },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            action: _vm.fileUploadUrl,
            headers: _vm.headers,
            limit: 1,
            "on-preview": _vm.previewFile,
            "before-upload": _vm.beforeUploadFile,
            "on-success": _vm.onSuccessFile,
            "on-error": _vm.onErrorFile,
            "on-change": _vm.onChange,
            "on-remove": _vm.onChange,
            "on-exceed": _vm.onExceed
          },
          model: {
            value: _vm.formInfo.fileList,
            callback: function($$v) {
              _vm.$set(_vm.formInfo, "fileList", $$v)
            },
            expression: "formInfo.fileList"
          }
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loading.submit,
                size: "small",
                type: "primary"
              }
            },
            [_vm._v(" 点击上传 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }