<template>
  <ics-dialog-inner width="60%" :visible.sync="dialog.visible" :title="dialog.title" submit-title="确认" cancel-title="返回" class="form-dialog" @submit="confirmContract">
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="基础合同名称">
              <el-input v-model="searchForm.contracName" placeholder="请输入要搜索的基础合同编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="买方名称">
              <el-input v-model="searchForm.buyFirmName" placeholder="请输入要搜索的买方名称" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <el-radio-group v-model="selectContract" class="table-radio-group">
        <ics-table-inner v-loading="loading.list" max-height="200px" :hide-column-btn="true" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
          <template slot="table-columns-before">
            <el-table-column fixed="left" label="选择" align="center" width="60">
              <template slot-scope="scope">
                <el-radio :label="scope.row" />
              </template>
            </el-table-column>
            <el-table-column fixed="left" label="序号" type="index" :index="indexMethod" />
          </template>
        </ics-table-inner>
      </el-radio-group>
    </div>
  </ics-dialog-inner>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import IcsDialogInner from '@/components/dialog-inner'
import utils from '@/assets/js/utils'
export default {
  name: 'IcsContractInner',
  components: { IcsDialogInner },
  mixins: [basePageListMixin],
  props: {
    dialog: {
      type: Object,
      default () {
        return {}
      }
    },
    contractInfo: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        contracName: '',
        buyFirmName: ''
      },
      selectContract: {},
      columnOption: [
        { label: '基础合同编号', prop: 'contracNo', formatter: this.utils.isEffective, minWidth: 120 },
        { label: '合同名称', prop: 'contracName', formatter: this.utils.isEffective, minWidth: 100 },
        { label: '买方名称', prop: 'buyFirmName', formatter: this.utils.isEffective, minWidth: 190 },
        { label: '卖方名称', prop: 'sellFirmName', formatter: this.utils.isEffective, minWidth: 190 },
        { label: '创建时间', prop: 'createdAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '创建人', prop: 'createdBy', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '状态', prop: 'usageStatus', formatter: (row, col, cell) => this.utils.statusFormat(Number(cell), 'assetsContractStatus'), minWidth: 100 },
      ]
    }
  },
  watch: {
    contractInfo: {
      handler (val) {
        if (val) {
          this.selectContract = val
        }
      },
      immediate: true
    }
  },
  methods: {
    getList() {
      this.loading.list = true
      this.searchForm.auditing = '1'
      this.api.assets.contract.listPage(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    confirmContract () {
      this.dialog.visible = false
      this.$emit('confirm', this.selectContract)
    }
  }
}
</script>

<style scoped>

</style>
